<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left">
        <div class="bonus-img">
          <img src="@/assets/img/shark.webp" alt="shark" />
        </div>
        <div class="bonus-cont">
          <div class="bonus-title">
            Ваши <span>бонусы</span> за регистрацию
          </div>
          <div class="bonus-item-cont">
            <div class="bonus-item">
              <h1>150<span>%</span></h1>
              <p>На первый депозит</p>
            </div>
            <div class="bonus-item">
              <h1>50<span>FS</span></h1>
              <p>За Telegram</p>
            </div>
          </div>
        </div>
      </div>
      <div class="registration-form modal-right">
        <div class="form-header">
          <h2 class="active">Зарегистрироваться</h2>
        </div>
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group">
            <input type="text" id="login" placeholder="Логин" />
          </div>
          <div class="form-group">
            <input type="tel" id="phone" placeholder="Номер телефона" required v-model="phone" />
          </div>
          <div class="form-group">
            <input
              type="password"
              id="password"
              placeholder="Пароль"
              required
              v-model="password"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              id="promo"
              placeholder="Промокод"
              v-model="promocode"
            />
          </div>
          <div class="form-group">
            <select id="currency" required v-model="currency">
              <option value="RUB" selected>RUB</option>
              <option value="UAH">UAH</option>
            </select>
          </div>
          <div class="footer">
            <div class="form-check">
              <label class="perm-check">
                <input type="checkbox" id="check" checked required />
                <p>
                  Я прочитал и согласен с <a href="#">Правилами и условиями</a>
                </p>
              </label>
            </div>
            <button type="submit" class="submit-btn">Зарегистрироваться</button>
            <div class="sign-in">
              <p>Уже есть аккаунт? <a href="#">Войти</a></p>
            </div>
          </div>
        </form>
        <div class="block-close">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 16 16"
            fill="#FFFFFF"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'SKUF',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
